
(function (){
    "use strict";

    $(document).ready(function () {

        

        


        //Animation burger
        $('.burger_menu').click(function () {
            if (!$('.burger_menu').hasClass('cross'))
                $('.burger_menu').addClass('cross');

            else
                $('.burger_menu').removeClass('cross');
        });


        $('.burger_menu').click(function () {
            $('.menu_mobile').toggleClass('menu_mobile-open');
        })



        // Accordion mobile menu //

        $(".menu_mobile_nav > li").on("click", function () {
            $(this).children(".sub-menu").slideToggle();
        });

        // slider review
        $('.slider-reviews').slick({
            arrows: false,
            dots: true,
            autoplay: true,
            dotsClass: 'slick-dots',
            slidesToShow: 1,
            slidesToScroll: 1,

        });

        // slider gallery project

        $('#slider-gallery').slick({
            arrows: false,
            dots: true,
            slidesToShow: 3,
            slidesToScroll: 3,
            responsive: [
                {
                    breakpoint: 600,
                    settings: {
                        dots: false,
                        arrows: false,
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        dots: false,
                        arrows: false,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
            ]
        });

        // slider lighbox gallery
        $('#slider-gallery').slickLightbox({
            src: 'src',
            itemSelector: '.slider-gallery-popup img',
            background: 'rgba(0, 0, 0, .7)'
        });

        // Slider sliderproject
        $('.container-slider').slick({
            arrows:true,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [
            {
            breakpoint: 1220,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
            },
            {
            breakpoint: 780,
            settings: {
                arrows: false,
                slidesToShow: 2,
                slidesToScroll: 2,
            }
            },
            {
            breakpoint: 600,
            settings: {
                arrows:false,
                slidesToShow: 1,
                slidesToScroll: 1,
            }
            }
        ]
        });

        // Accordion

        var acc = document.getElementsByClassName("accordion");
        var i;

        for (i = 0; i < acc.length; i++) {
            acc[i].addEventListener("click", function () {
                this.classList.toggle("active-accordion");
                var panel = this.nextElementSibling;
                if (panel.style.maxHeight) {
                    panel.style.maxHeight = null;
                } else {
                    panel.style.maxHeight = panel.scrollHeight + "px";
                }
            });
        }

        // Dropdown Filtri

        // $(".dropdown").click(function () {
        //     $(this).find(".dropdown-content").slideToggle("fast");
        // });

        
        // $(document).on("click", function (event) {
        //     var $trigger = $(".dropdown");
        //     if ($trigger !== event.target && !$trigger.has(event.target).length) {
        //         $(".dropdown-content").slideUp("fast");
        //     }
        // });

        $(".dropdown").click(function (event) {
        event.stopPropagation();
        $(this).find(".dropdown-content").slideToggle("fast");
        });

        $(document).on("click", function (event) {
            var $trigger = $(".dropdown");
            var $dropdownContent = $(".dropdown-content");
            
            if (!$trigger.is(event.target) && $trigger.has(event.target).length === 0 &&
                !$dropdownContent.is(event.target) && $dropdownContent.has(event.target).length === 0) {
                $dropdownContent.slideUp("fast");
            }
        });
        
        // Aggiungi un gestore di clic specifico per gli input all'interno di .dropdown-content
        $(".dropdown-content input").click(function (event) {
            event.stopPropagation();
        });


    }); // close documet get ready

})(jQuery);

